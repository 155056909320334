import React, { useEffect, useState } from "react";
import "./AssignmentReport.css";
import { Circle, Line } from "rc-progress";
import { getUserData } from "../../../utls/utls";
// import ReportData from "./ReportData";

const AssignmentReport = ({data,average,user_id, courseName}) => {
  console.log("Report Data", data)
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    usn: ""
  })

  

  useEffect(() => {
    const fetchUserData = async (user_id) =>{
      try {
        const res = await getUserData(user_id)
        // console.log(res)
        setUserData({
          ...userData,
          name: res?.data?.user[0]?.name,
          email: res?.data?.user[0]?.email,
          usn: res?.data?.user[0]?.roll_no
        })
      } catch (error) {
        console.log(error)
      }
    }

    fetchUserData(user_id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  return (
    <>
      <div className="AssignmentReportContainer">
        <div className="AssignmentReportNav">
          <div className="AssignmentReportNavLogo"></div>
          <h1 className="AssignmentReportHeader">Assignment Report</h1>
          <div className="AssignmentReportLine"></div>
        </div>
        <div className="AssignmentReportDetail">
          <h1 className="AssignmentReportDetailHeading">Student Details:</h1>
          <div className="AssignmentReportStudentDetail">
            <h1>Name: {userData?.name}</h1>
            <h1>Email: {userData?.email}</h1>
            <h1>USN: {userData?.usn}</h1>
          </div>
          <h1 className="AssignmentReportDetailHeading">Assignment Details:</h1>
          <h2 className="AssignmentReportDetailPara">
            Chapter: Vision to Revenue
          </h2>
          <h2 className="AssignmentReportDetailPara">
            Course: {courseName}
          </h2>
        </div>
        <h1
          className="AssignmentReportDetailHeading"
          style={{ marginTop: "3rem" }}
        >
          Assessment Results
        </h1>
        <div className="AssignmentReportLine"></div>
        <h1 className="AssignmentReportDetailPara">
          The Assignment submitted by you was evaluated to measure the
          parameters and scored out of 10
        </h1>

        {data?.map((elm,index) => {
          return <AssignmentPercentage percentage={elm.score} name={elm.parameter} key={index}/>
        })}

        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
        <p style={{fontSize: "1.6rem"}}>Average Overall Score: {average}/100</p>
        <div style={{ width: 100, margin: "0 auto" }}>
          <Circle
            percent={average}
            strokeWidth={7}
            steps={{
              count: 15,
              space: 1,
            }}
            strokeColor={"#0f8693"}
          />
        </div>
        </div>
      </div>
    </>
  );
};

export default AssignmentReport;

const AssignmentPercentage = ({percentage, name}) => {
  // console.log(percentage)
  return (
    <>
      <div className="AssignmentReportResult">
        <div className="AssignmentReportResultLeft">
          <p style={{fontSize: "1.6rem"}}>{name}: {percentage}/100</p>
        </div>
        <div className="AssignmentReportResultRight">
          <Line percent={percentage} strokeWidth={1} strokeColor="#0f8693" />
        </div>
      </div>
    </>
  );
};
