import React, { useEffect, useState } from 'react'
import './UserAccount.css'
import { useLocation } from 'react-router-dom'
import MenuBar from '../MenuBar/MenuBar'
import { getEnrollmentId, getCourseByCourseId } from '../../utls/utls'

const UserAccount = () => {
    const { state } = useLocation()
    const [courses, setCourses] = useState([])

    const getCourse = async (user_id) => {
        const res = await getEnrollmentId(user_id);
        const course = await getCourseByCourseId(res?.data?.items);
        setCourses(course)
    }

    useEffect(() => {
        getCourse(state.student.user_id)
        console.log("useeffect")
    }, [state.student.user_id])
    return (
        <>
            <div className='userAccount-container'>
                <MenuBar userData={state.user} />
                <div className='userAccount-main'>
                    <div>
                        <div>
                            {/* <h1>Name - {state.student.name}</h1> */}
                            <label for='name' className='nameLabel'>Name</label><br />
                            <input type='text' placeholder={state.student.name} id='name' disabled className='nameInput'/><br />

                            <label for='usn' className='nameLabel'>Usn - No</label><br />
                            <input type='text' placeholder={state.student.roll_no} id='usn' disabled className='nameInput'/><br />

                            <label for='semester' className='nameLabel'>Semester</label><br />
                            <input type='text' placeholder={state.student.semester} id='semester' disabled className='nameInput'/><br />

                            <label for='branch' className='nameLabel'>Branch</label><br />
                            <input type='text' placeholder={state.student.branch.name} id='branch' disabled className='nameInput'/><br />

                            <label for='phone' className='nameLabel'>Phone</label><br />
                            <input type='text' placeholder={state.student.phone} id='phone' disabled className='nameInput'/><br />

                            <label for='email' className='nameLabel'>Email</label><br />
                            <input type='text' placeholder={state.student.email} id='email' disabled className='nameInput'/><br />
                        </div>

                        <div>
                            {courses && <h1>Courses enrolled</h1>}

                            {courses?.map((elm, index) => (
                                <div className='mini-courseCard' key={index}>
                                    <img src={elm?.product?.card_image_url} alt='course' />
                                    <h1>{elm.course.name}</h1>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <h1>Assignment Submitted</h1>
                        <div>
                            <div style={{ display: "flex" }}>
                                <h1>Do and Learn - 1</h1>
                                <button className='reportBtn'>View Report</button>
                                <input type='text' placeholder='Enter marks' className='inputBtn' />
                            </div>

                            <div style={{ display: "flex" }}>
                                <h1>Do and Learn - 2</h1>
                                <button className='reportBtn'>View Report</button>
                                <input type='text' placeholder='Enter marks' className='inputBtn' />
                            </div>

                            <div style={{ display: "flex" }}>
                                <h1>Do and Learn - 3</h1>
                                <button className='reportBtn'>View Report</button>
                                <input type='text' placeholder='Enter marks' className='inputBtn' />
                            </div>

                            <div style={{ display: "flex" }}>
                                <h1>Do and Learn - 4</h1>
                                <button className='reportBtn'>View Report</button>
                                <input type='text' placeholder='Enter marks' className='inputBtn' />
                            </div>
                        </div>

                        <h1>Quiz Attempted</h1>
                        <div>
                            <h1>Session Evaluation - 1</h1>
                            <h1>Session Evaluation - 2</h1>
                            <h1>Session Evaluation - 3</h1>
                            <h1>Session Evaluation - 4</h1>
                            <h1>Session Evaluation - 5</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserAccount
