import React from 'react'
import "./ReportModal.css"
import { IoIosCloseCircleOutline } from "react-icons/io";
import AssignmentReport from '../AssignmentReport/AssignmentReport';
import { AssignmentData } from '../StudentReport/ReportData'


const ReportModal = ({ setShowModal }) => {
    const averageScore = 70
    const user_id = localStorage.getItem("id");
    return (
        <>
            <div className='reportModal_container'>
                <IoIosCloseCircleOutline onClick={() => setShowModal(false)} className='reportModalClose' />
                {/* <h1>Report Modal Container</h1> */}

                <AssignmentReport
                    data={AssignmentData}
                    average={averageScore}
                    user_id={user_id}
                    // courseName={courseName}
                />
            </div>
        </>
    )
}

export default ReportModal
