import React, { useState } from "react";
import "./ActivityEvaluation.css";
import Navbar from "../Shared/Navbar/Navbar";
import { ActivityPostChatGptResponse } from "../../utls/utls";
import AssignmentReport from "../Shared/AssignmentReport/AssignmentReport";

const ActivityEvaluation = () => {
  const [activityData, setActivityData] = useState({
    post: "",
  });
  const [showSubmission, setShowSubmission] = useState(false);
  const [resData, setResData] = useState()
  const [averageScore, setAverageScore] = useState(0)
  const user_id = localStorage.getItem("id");
  const location = window.location.pathname;
  const splitedUrl = location.split('/')
  // console.log("ref", splitedUrl);
  const courseName = splitedUrl[3]
  let name, value;
  const handleContactChange = (e) => {
    name = e.target.name;
    value = e.target.value;

    setActivityData({ ...activityData, [name]: value });
  };
  // console.log(Math.round(Math.random() * 2))

  const handleSubmit = async () => {
    try {
      const resp = await ActivityPostChatGptResponse(activityData.post);
      const gptArr = []
      let totalScore = 0;
      const resultData = resp?.data?.choices[0]?.message?.content;
      for (let index = 0; index < Object.keys(JSON.parse(resultData)).length; index++) {
        let gptData = {};
        gptData.parameter = Object.keys(
          JSON.parse(resultData)
        )[index];

        gptData.score = Object.values(
          JSON.parse(resultData)
        )[index];
        gptArr.push(gptData);
        totalScore += Object.values(
          JSON.parse(resultData)
        )[index];
        setAverageScore(
          totalScore /
          Object.keys(
            JSON.parse(resultData)
          ).length
        );
      }
      setResData(gptArr)
      setShowSubmission(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="activityEvaluationConatiner">
        <Navbar />
        {showSubmission ? (
          <>
            <h1>Your activity is submitted successfully</h1>
            <AssignmentReport
              data={resData}
              average={averageScore}
              user_id={user_id}
              courseName={courseName}
            />
          </>
          /* {resData && (
          <AssignmentReport
            data={resData}
            average={averageScore}
            user_id={user_id}
            courseName={courseName}
          />
        )} */
        ) : (
          <div>
            <h1>Please submit your Activity here</h1>
            <div className="activityTextAreaContainer">
              <textarea
                type="text"
                name="post"
                value={activityData.post}
                onChange={handleContactChange}
                placeholder="Your text will come here..."
                className="activity_input"
                style={{ resize: "none" }}
              />
              <button className="contact_submit_btn" onClick={handleSubmit}>
                SUBMIT
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ActivityEvaluation;
