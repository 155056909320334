import { createSlice } from '@reduxjs/toolkit';

// export const fetchUser = createAsyncThunk("fetchUser", async () => {
//     const resp = 
// })

const userSlice = createSlice({
    name: "User",
    initialState: [],
    reducers: {
        getUser: (state, action) => {
            state = [...state, action.payload]
        }
    }
})

export default userSlice.reducer;