import React from "react";
import "./Footer.css";
import { ImLinkedin } from "react-icons/im";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="footer_container">
        <div className="footer_contact">
          <h1 className="footer_heading">Contact Us</h1>

          <div className="Contact_details">
            <div className="left_cont_details">
              <h1 className="footer_contact_heading">Address</h1>
              <p className="footer_contact_para">MindMatrix Labs CLi House</p>
              <p className="footer_contact_para">
                #149, 14th Main, 4th Block Koramangala, Bangalore – 560 034
              </p>
            </div>
            <div className="right_cont_details">
              <h1 className="footer_contact_heading">Contact No</h1>
              <p className="footer_contact_para">+91 9611546444</p>

              <h1 className="footer_contact_heading">Email</h1>
              <p className="footer_contact_para">contact@mindmatrix.io</p>
            </div>
          </div>
          <Link
            to="https://www.google.com/maps/place/CL+Infotech+Pvt+Ltd/@12.934102,77.627262,15z/data=!4m6!3m5!1s0x3bae145cdaf5c953:0xdfb1132e4ea2b426!8m2!3d12.934102!4d77.627262!16s%2Fg%2F12338wcjs?entry=ttu"
            target="_blank"
          >
            <div className="footer_map_img"></div>
          </Link>
        </div>

        {/* <div className="footer_middle">
          <h1 className="footer_heading">Just say hello!</h1>

          <div className="main_form">
            <form>
              <div className="input_item">
                <div className="img_box">
                  <div className="image_person"></div>
                </div>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Your Full Name"
                  className="input_box"
                />
              </div>

              <div className="input_item">
                <div className="img_box">
                  <div className="image_phone"></div>
                </div>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="Your Phone number"
                  className="input_box"
                />
              </div>

              <div className="input_item">
                <div className="img_box">
                  <div className="image_email"></div>
                </div>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Your email address"
                  className="input_box"
                />
              </div>

              <div className="input_item_textarea">
                <div className="img_box">
                  <div className="image_message"></div>
                </div>
                <textarea
                  id="message"
                  name="message"
                  className="textarea_box"
                  placeholder="Write your message"
                ></textarea>
              </div>
            </form>
          </div>
        </div> */}

        <div className="follow">
          <h1 className="footer_heading">Follow us</h1>

          <div className="social_media">
            <Link
              to="https://www.linkedin.com/company/mindmatrixio/?originalSubdomain=in"
              target="_blank"
              className="input_item"
            >
              <div className="img_box">
                <ImLinkedin className="image_linkedin" />
              </div>
              <button className="social_button">LinkedIn</button>
            </Link>

            <Link
              to="https://www.instagram.com/mindmatrixed/"
              target="_blank"
              className="input_item"
            >
              <div className="img_box">
                <div className="image_insta"></div>
              </div>
              <button className="social_button">Instagram</button>
            </Link>

            <Link
              to="https://www.youtube.com/@mindmatrixed2588"
              target="_blank"
              className="input_item"
            >
              <div className="img_box">
                <div className="image_facebook"></div>
              </div>
              <button className="social_button">YouTube</button>
            </Link>

            <Link
              to="https://twitter.com/mindmatrixed"
              target="_blank"
              className="input_item"
            >
              <div className="img_box">
                <div className="image_twitter"></div>
              </div>
              <button className="social_button">X</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
