import React from 'react'
import './Recommendation.css'
import ReccCard from './Recc_Card/Recc_Card'

const Reccomendation = ({data}) => {
  return (
    <div className="reccomendation_container">
      <div className="recc_card_container">
          {data?.map((elm, index) => {
            return <ReccCard elm={elm} key={index} />;
          })}
        </div>
    </div>
  )
}

export default Reccomendation;