import React, { useState } from "react";
import "./Support.css";
import { faqChatGptResponse } from "../../../../utls/utls";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";

const Support = () => {
  const [botData, setBotData] = useState([
    {
      sender: "",
      message: "Hi This is MindMatrix bot ask your queries",
    },
  ]);
  // const [question, setQuestion] = useState();
  const [typing, setTyping] = useState(false);
  // const faqData = [
  //   {
  //     QA: "This is the testing question number 1 for testing the user interface",
  //     ANS: "This is the testing Answer number 1 for testing the user interface for the Answer by the chat bot",
  //   },
  //   {
  //     QA: "This is the testing question number 2 for testing the user interface",
  //     ANS: "This is the testing Answer number 2 for testing the user interface for the Answer by the chat bot",
  //   },
  //   {
  //     QA: "This is the testing question number 3 for testing the user interface",
  //     ANS: "This is the testing Answer number 3 for testing the user interface for the Answer by the chat bot",
  //   },
  //   {
  //     QA: "This is the testing question number 4 for testing the user interface",
  //     ANS: "This is the testing Answer number 4 for testing the user interface for the Answer by the chat bot",
  //   },
  //   {
  //     QA: "This is the testing question number 5 for testing the user interface",
  //     ANS: "This is the testing Answer number 5 for testing the user interface for the Answer by the chat bot",
  //   },
  //   {
  //     QA: "This is the testing question number 6 for testing the user interface",
  //     ANS: "This is the testing Answer number 6 for testing the user interface for the Answer by the chat bot",
  //   },
  //   {
  //     QA: "This is the testing question number 7 for testing the user interface",
  //     ANS: "This is the testing Answer number 7 for testing the user interface for the Answer by the chat bot",
  //   },
  //   {
  //     QA: "This is the testing question number 8 for testing the user interface",
  //     ANS: "This is the testing Answer number 8 for testing the user interface for the Answer by the chat bot",
  //   },
  //   {
  //     QA: "This is the testing question number 9 for testing the user interface",
  //     ANS: "This is the testing Answer number 9 for testing the user interface for the Answer by the chat bot",
  //   },
  //   {
  //     QA: "This is the testing question number 10 for testing the user interface",
  //     ANS: "This is the testing Answer number 10 for testing the user interface for the Answer by the chat bot",
  //   },
  //   {
  //     QA: "This is the testing question number 11 for testing the user interface",
  //     ANS: "This is the testing Answer number 11 for testing the user interface for the Answer by the chat bot",
  //   },
  //   {
  //     QA: "This is the testing question number 12 for testing the user interface",
  //     ANS: "This is the testing Answer number 12 for testing the user interface for the Answer by the chat bot",
  //   },
  // ];
// console.log(botData)
  const handleSubmit = (message) => {
    setTyping(true)
    const systemMessage = {
      role: "system",
      content:
        "Explain me like i am learning android app developement in kotlin",
    };

    const data = {
      sender: "user",
      message: message,
      direction: "outgoing"
    };

    const finalData = [...botData, data];
    setBotData(finalData);
    fetchBotResult(finalData, systemMessage);
  };

  async function fetchBotResult(data, systemData) {
    let messageData = data.map((elm) => {
      let role = "";
      if (elm.sender === "MindMatrix") {
        role = "assistant"
      } else {
        role = "user"
      }
      return { role: role, content: elm.message}
    })
    const resp = await faqChatGptResponse(messageData, systemData);
  
    setBotData([...data, {
      sender: "MindMatrix",
      message: resp?.data?.choices[0]?.message?.content
    }]);
    setTyping(false)
  }
  return (
    <div className="support_Container">
      <div className="chat_box">
        <MainContainer>
          <ChatContainer>
            <MessageList
              autoScrollToBottom={true}
              typingIndicator={
                typing ? (
                  <TypingIndicator content="MindMatrix is responding" />
                ) : null
              }
            >
              {botData.map((message, i) => {
                return <Message key={i} model={message} />;
              })}
            </MessageList>
            <MessageInput
              placeholder="Type message here"
              onSend={handleSubmit}
              attachButton={false}
              autoFocus
            />
          </ChatContainer>
        </MainContainer>
      </div>
    </div>
  );
};

export default Support;
