import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'
import "./Assignment.css";
import {
  chatGptResponse,
  getUserDetail,
  uploadAssignment,
} from "../../utls/utls";
import Navbar from "../Shared/Navbar/Navbar";
import AssignmentReport from "../Shared/AssignmentReport/AssignmentReport";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Assignment = () => {
  const testLocation = useLocation()
  const [userData, setUserData] = useState({
    first_name: "",
    name: "",
    user_image: "",
  });
  const [pdfFile, setPdfFile] = useState(null);
  const [resData, setResData] = useState();
  const [averageScore, setAverageScore] = useState(0);
  const user_id = localStorage.getItem("id");

  const location = window.location.pathname;
  const splitedUrl = location.split('/')
  // console.log("ref", splitedUrl);
  const courseName = splitedUrl[3]
  // const previousUrl = window.history.state.prevUrl
  console.log(testLocation)
  console.log(document.referrer)

  const handleFileChange = async (event) => {
    setPdfFile(event?.target?.files[0]);
  };

  // Handle the assignment submission by chatgpt
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (pdfFile) {
        const formData = new FormData();
        formData.append("pdfFile", pdfFile);
        const res = await uploadAssignment(formData);
        // console.log(res);

        const result = await chatGptResponse(res?.data?.res?.trim());
        let gptArr = [];
        let totalScore = 0;
        const resultData = result?.data?.choices[0]?.message?.content;
        for (
          let index = 0;
          index <
          Object.keys(JSON.parse(resultData))
            .length;
          index++
        ) {
          let gptData = {};
          gptData.parameter = Object.keys(
            JSON.parse(resultData)
          )[index];
          gptData.score = Object.values(
            JSON.parse(resultData)
          )[index];
          gptArr.push(gptData);
          totalScore += Object.values(
            JSON.parse(resultData)
          )[index];
          setAverageScore(
            totalScore /
            Object.keys(
              JSON.parse(resultData)
            ).length
          );
        }
        setResData(gptArr);
      } else {
        toast.warning("Please select a file");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(userData)

  useEffect(() => {
    const userDetails = async (user_id) => {
      try {
        const res = await getUserDetail(user_id);
        // console.log(res);
        setUserData({
          ...userData,
          first_name: res?.data?.first_name,
          name: res?.data?.full_name,
          user_image: res?.data?.avatar_url,
        });
      } catch (error) {
        console.log(error);
      }
    };

    if (!userData.name) {
      userDetails(user_id);
    }
  }, [userData, user_id]);

  return (
    <>
      <div className="assignment_container">
        <Navbar userData={userData} />
        <h1>Hi {userData?.first_name}, You can submit your Assignment here{document.referrer}</h1>

        <input
          type="file"
          accept="application/pdf"
          onChange={handleFileChange}
          className="files"
        />
        <button className="submit_assignment_btn" onClick={handleSubmit}>
          Submit
        </button>
        {resData && (
          <AssignmentReport
            data={resData}
            average={averageScore}
            user_id={user_id}
            courseName={courseName}
          />
        )}
      </div>
      <ToastContainer style={{ fontSize: "1.35rem" }} />
    </>
  );
};

export default Assignment;
