import React from "react";
import './UserBranchPrefance.css'
import AuthLeft from "../Shared/authLeft/authLeft";

const UserBranchPrefance = () => {
  return (
    <>
      <div className="user_branch_prefance_container">
        <AuthLeft />
        <div className="user_branch_prefance_right">
          <div className="student_details_hero_icon"></div>
        </div>
      </div>
    </>
  );
};

export default UserBranchPrefance;
