import React, { useEffect, useState } from 'react'
import './TeacherDashboard.css'
import { getUserByCollegeId } from '../../utls/utls'
import MenuBar from '../MenuBar/MenuBar'
import { useNavigate } from 'react-router-dom'
import { BarChart, PieChart, LineChart } from '@mui/x-charts';

const TeacherDashboard = () => {
    const navigate = useNavigate()
    const [userData, setUserData] = useState({
        first_name: "",
        name: "",
        user_image: "",
    })
    const [user, setUser] = useState([])
    // const [enrollment, setEnrollment] = useState([])

    // setInterval(async () => {
    //     const res = await getEnrollmentByUserId(201621686)
    //     console.log(res)
    // }, 10000);

    const userDetails = async (user_id) => {
        const res = JSON.parse(window.sessionStorage.getItem("userThincific"));
        const userRes = await getUserByCollegeId('6477e1d15e21f533c359a07c')
        setUser(userRes?.data?.user)

        setUserData({
            ...userData,
            first_name: res?.data?.first_name,
            name: res?.data?.full_name,
            user_image: res?.data?.avatar_url,
        });
    }


    useEffect(() => {
        if (!userData.first_name) {
            userDetails()
        }
        // getEnrollment()
    })

    return (
        <>
            <div className='teacherDashboard-container'>
                <MenuBar userData={userData} />
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: '100%' }}>
                        <h1>Progress</h1>
                        <BarChart
                            series={[
                                { data: [35, 44, 24, 34] },
                                { data: [51, 6, 49, 30] },
                                { data: [15, 25, 30, 50] },
                                { data: [60, 50, 15, 25] },
                            ]}
                            height={290}
                            xAxis={[{ data: ['Q1', 'Q2', 'Q3', 'Q4'], scaleType: 'band' }]}
                            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                        />
                    </div>

                    <div style={{ width: '100%', height: '100%' }}>
                        <h1>Assignment</h1>
                        <PieChart
                            series={[
                                {
                                    data: [
                                        { id: 0, value: 10, label: 'Submitted' },
                                        { id: 1, value: 15, label: 'Not Submitted' },
                                        // { id: 2, value: 20, label: 'series C' },
                                    ],
                                },
                            ]}
                            width={400}
                            height={290}
                            margin={{ top: 10, bottom: 30, left: 0, right: 80 }}
                        />
                    </div>

                    <div style={{ width: '100%', height: '100%' }}>
                        <h1>Attendance</h1>
                        <LineChart
                            xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                            series={[
                                {
                                    data: [2, 5.5, 2, 8.5, 1.5, 5],
                                },
                            ]}
                            width={500}
                            height={300}
                        />
                    </div>
                </div>


                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Branch</th>
                            <th>Email</th>
                            <th>USN No</th>
                            <th>Phone No</th>
                            <th>Semester</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {user?.map((elm, index) => (
                            <tr key={index}>
                                <td>{elm.name}</td>
                                <td>{elm.branch.name}</td>
                                <td>{elm.email}</td>
                                <td>{elm.roll_no}</td>
                                <td>{elm.phone}</td>
                                <td>{elm.semester}</td>
                                <td><button onClick={() => {
                                    navigate(`/user-account/${elm._id}`, { state: { student: elm, user: userData } })
                                }}>Details</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </>
    )
}

export default TeacherDashboard
