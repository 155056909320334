import React from "react";
import './UserDomainPrefance.css'
import AuthLeft from "../Shared/authLeft/authLeft";


const UserDomainPrefance = () => {
  return (
    <>
      <div className="user_domain_prefance_container">
        <AuthLeft />
        <div className="user_domain_prefance_right">
          <div className="student_details_hero_icon"></div>
        </div>
      </div>
    </>
  );
};

export default UserDomainPrefance;
