import React, { useEffect, useState } from "react";
import {
  getUserDetail,
  // ActivityPostChatGptResponse
} from "../../utls/utls";
import Navbar from "../Shared/Navbar/Navbar";

const ActivityPage = () => {
  const [userData, setUserData] = useState({
    first_name: "",
    name: "",
    user_image: "",
  });
  const [pdfFile, setPdfFile] = useState(null);
  const user_id = localStorage.getItem("id");
  console.log(pdfFile)

  const handleFileChange = async (event) => {
    setPdfFile(event?.target?.files[0]);
  };

  const handleSubmit = async () => {
    console.log("Submitted")
  }

  useEffect(() => {
    const userDetails = async (user_id) => {
      try {
        const res = await getUserDetail(user_id);
        // console.log(res);
        setUserData({
          ...userData,
          first_name: res?.data?.first_name,
          name: res?.data?.full_name,
          user_image: res?.data?.avatar_url,
        });
      } catch (error) {
        console.log(error);
      }
    };

    if (!userData.name) {
      userDetails(user_id);
    }
  }, [userData, user_id]);
  return (
    <div className="assignment_container">
        <Navbar userData={userData} />
        <h1>Hi {userData?.first_name}, You can submit your Activity here</h1>

        <input
          type="file"
          accept="application/pdf"
          onChange={handleFileChange}
          className="files"
        />
        <button className="submit_assignment_btn" onClick={handleSubmit}>
          Submit
        </button>
        {/* {resData && (
          <AssignmentReport
            data={resData}
            average={averageScore}
            user_id={user_id}
            courseName={courseName}
          />
        )} */}
      </div>
  )
}

export default ActivityPage