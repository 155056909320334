const TestimonialData = [
  {
    image: require("../Shared/TestimonialCard/image/pradyuman_student.jpeg"),
    name: "Pradyuman",
    studentWord:
      "For a student other than what is learnt at school that student must have some skills and knowledge to apply what is learnt in our day to day life, those skills and knowledge about a particular domain was taught to me by many expertise and Industrialist in MindMatrix. Either the technological or non technical knowledge was learnt with lot of fun. Here I learnt a lot of things as well as my confidence was boosted",
  },
  {
    image: require("../Shared/TestimonialCard/image/krushi_student.jpg"),
    name: "Krushi",
    studentWord:
      "I would like to thank team mind matrix for giving me the opportunity to do internship. This was my first experience and it was really good and got chance to know about many things and also got a chance to interact with great personalities.",
  },
  {
    image: require("../Shared/TestimonialCard/image/akshay_student.jpg"),
    name: "Akshay",
    studentWord:
      "The internship which i had done in MindMatrix with the industrialist and expertise helped me gain some knoledge and skills with the help of their experience. I thank MindMatrix for providing me an opportunity and supporting many students like me !!",
  },
];

export default TestimonialData;
