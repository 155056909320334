import React from 'react'
import './ViewAllCourses.css'
import Navbar from '../Shared/Navbar/Navbar'

const ViewAllCourses = () => {
  return (
    <>
        <div className="viewAllCourses_container">
            <Navbar />
            <h1>Courses</h1>
            <div className='viewAllCourses_btn_container'>
                <button className='viewAllCourses_select_button'>Ability Enhancement Courses</button>
                <button className='viewAllCourses_select_button'>Activity</button>
                <button className='viewAllCourses_select_button'>Internships</button>
                <button className='viewAllCourses_select_button'>Professional Courses</button>
                <button className='viewAllCourses_select_button'>Workshops</button>
            </div>

            <div className="viewAllCourses_cards_container">
                {cardData.map((elm) => {
                    return <Card data={elm}/>
                })}
            </div>
        </div>
    </>
  )
}

export default ViewAllCourses;

const Card = ({data}) => {
    return (
        <>
            <div className="viewAllCourse_card_container">
                <h1>{data.heading}</h1>
                <h1 style={{marginBottom: '2rem'}}>{data.category}</h1>
            </div>
        </>
    )
}

const cardData = [
    
    {
        img: 'img',
        heading: "Data Operations in Android Apps and Insight into Views toolkit",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "AEC",
    },
    {
        img: 'img',
        heading: "App Architecture and Connecting to Internet",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "AEC",
    },
    {
        img: 'img',
        heading: "BUILD APPS WITH KOTLIN",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "AEC",
    },
    {
        img: 'img',
        heading: "IOT FOR SMART INFRASTRUCTURE",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "AEC",
    },
    {
        img: 'img',
        heading: "EXPLORE N EXHIBIT",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "Activity",
    },
    {
        img: 'img',
        heading: "IOT - INTERNSHIP",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "Internships",
    },
    {
        img: 'img',
        heading: "GEN AI SKILL BADGE",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "Professional Courses",
    },
    {
        img: 'img',
        heading: "GEN AI TRAINING PROGRAM",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "Professional Courses",
    },
    {
        img: 'img',
        heading: "GOOGLE CYBERSECURITY CERTIFICATION",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "Professional Courses",
    },
    {
        img: 'img',
        heading: "INTRODUCTION TO MATLAB",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "Professional Courses",
    },
    {
        img: 'img',
        heading: "BUILD APPS FOR GOOGLE CHAT",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "Professional Courses",
    },
    {
        img: 'img',
        heading: "FUNDAMENTALS OF SOLAR CELLS",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "Professional Courses",
    },
    {
        img: 'img',
        heading: "Data Cards Playbook",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "Professional Courses",
    },
    {
        img: 'img',
        heading: "Wear Os",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "Professional Courses",
    },
    {
        img: 'img',
        heading: "Introduction to Algorithms",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "Professional Courses",
    },
    {
        img: 'img',
        heading: "Public Transportation System",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "Professional Courses",
    },
    {
        img: 'img',
        heading: "Python Tutorial",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "Professional Courses",
    },
    {
        img: 'img',
        heading: "User Experience Management in Android App Development",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "Professional Courses",
    },
    {
        img: 'img',
        heading: "Enhancing the User Experience in Android Apps",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "Professional Courses",
    },
    {
        img: 'img',
        heading: "Brochure Website",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "Professional Courses",
    },
    {
        img: 'img',
        heading: "PowerUp - Ignite your Career With Generative AI2",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "Workshops",
    },
    {
        img: 'img',
        heading: "PowerUp - Ignite your Career with Generative AI - 1st Batch",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "Workshops",
    },
    {
        img: 'img',
        heading: "Stay Ahead With Generative AI",
        link: "https://xenodochial-kare-510bae.netlify.app",
        category: "Workshops",
    },
]