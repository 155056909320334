import React from 'react'
import './Report.css'

const Report = () => {
  return (
    <>
        <div className='report_container'>
            <div className='report_nav'></div>
            <div className='report_hero'>
                <div className='report_left'>
                    {/* <h1>Courses enrolled into</h1> */}
                    <div className='enrolled_courses'>
                        <h1>Course Name</h1>
                        <h1>Percentage completed</h1>
                        <h1>Assignment list and report </h1>
                    </div>
                    <div className='enrolled_courses'></div>
                    {/* <h1>University courses</h1> */}
                    <div className='university_courses'></div>
                    <div className='university_courses'></div>
                </div>

                {/* Academic details */}
                <div className='report_right'>
                    <h1>USN</h1>
                    <h1>Branch</h1>
                    <h1>semester</h1>
                    <h1>College</h1>
                    <h1>University</h1>
                </div>
            </div>
        </div>
    </>
  )
}

export default Report;


// Test case for Array of object(Data) 


// import React from 'react';

// const ObjectList = () => {
//   // Array of objects
//   const objects = [
//     { id: 1, name: 'Object 1', description: 'Description of Object 1' },
//     { id: 2, name: 'Object 2', description: 'Description of Object 2' },
//     { id: 3, name: 'Object 3', description: 'Description of Object 3' }
//   ];

//   return (
//     <div>
//       <h2>List of Objects</h2>
//       <ul>
//         {objects.map(obj => (
//           <li key={obj.id}>
//             <strong>{obj.name}</strong>: {obj.description}
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default ObjectList;


// // ObjectList.test.js

// import React from 'react';
// import { render, screen } from '@testing-library/react';
// import ObjectList from './ObjectList'; // Import your component

// describe('ObjectList component', () => {
//   test('renders a list of objects correctly', () => {
//     // Mock data
//     const objects = [
//       { id: 1, name: 'Object 1', description: 'Description of Object 1' },
//       { id: 2, name: 'Object 2', description: 'Description of Object 2' },
//       { id: 3, name: 'Object 3', description: 'Description of Object 3' }
//     ];

//     render(<ObjectList />); // Render the component

//     // Verify that each object's name and description are rendered
//     objects.forEach(obj => {
//       const objectName = screen.getByText(obj.name);
//       const objectDescription = screen.getByText(obj.description);
//       expect(objectName).toBeInTheDocument();
//       expect(objectDescription).toBeInTheDocument();
//     });
//   });
// });

// import React from 'react';

// const ObjectComponent = () => {
//   // Array of objects
//   const objects = [
//     { id: 1, name: 'Object 1', description: 'Description of Object 1' },
//     { id: 2, name: 'Object 2', description: 'Description of Object 2' },
//     { id: 3, name: 'Object 3', description: 'Description of Object 3' }
//   ];

//   return (
//     <div>
//       <h2>List of Objects</h2>
//       <ul>
//         {objects.map(obj => (
//           <li key={obj.id}>
//             <strong>{obj.name}</strong>: {obj.description}
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default ObjectComponent;

// import React from 'react';
// import ObjectComponent from './ObjectComponent'; // Adjust the path as per your project structure

// const App = () => {
//   return (
//     <div className="App">
//       <ObjectComponent />
//     </div>
//   );
// };

// export default App;


// import React from 'react';

// const ObjectComponent = () => {
//   // Array of objects
//   const objects = [
//     { id: 1, name: 'Object 1', description: 'Description of Object 1' },
//     { id: 2, name: 'Object 2', description: 'Description of Object 2' },
//     { id: 3, name: 'Object 3', description: 'Description of Object 3' }
//   ];

//   // You can perform any operations on objects here

//   return null; // This component does not render anything directly
// };

// export default ObjectComponent;


// import React from 'react';
// import ObjectComponent from './ObjectComponent'; // Adjust the path as per your project structure

// const App = () => {
//   return (
//     <div className="App">
//       <ObjectComponent />
//       {/* You can use objects array here or pass it as props */}
//     </div>
//   );
// };

// export default App;









// Test case for dropdown

// import React, { useState } from 'react';

// const Dropdown = () => {
//   // State to manage the selected option
//   const [selectedOption, setSelectedOption] = useState('');

//   // Function to handle changes in the dropdown selection
//   const handleSelectChange = (event) => {
//     setSelectedOption(event.target.value);
//   };

//   return (
//     <div>
//       <h2>Dropdown Example</h2>
//       <select value={selectedOption} onChange={handleSelectChange}>
//         <option value="">Select an option</option>
//         <option value="option1">Option 1</option>
//         <option value="option2">Option 2</option>
//         <option value="option3">Option 3</option>
//       </select>
//       <p>Selected Option: {selectedOption}</p>
//     </div>
//   );
// };

// export default Dropdown;


// import React from 'react';
// import { render, fireEvent } from '@testing-library/react';
// import Dropdown from './Dropdown'; // assuming the Dropdown component is in the same directory

// describe('Dropdown component', () => {
//   test('renders dropdown with initial state', () => {
//     const { getByText, getByLabelText } = render(<Dropdown />);
    
//     // Check if dropdown renders with default text
//     const selectElement = getByLabelText('Dropdown Example');
//     expect(selectElement).toBeInTheDocument();
    
//     // Check if initial option is selected
//     const selectedOption = getByText('Selected Option: ');
//     expect(selectedOption.textContent).toContain(''); // initial selected option should be an empty string
//   });

//   test('updates selected option on change', () => {
//     const { getByLabelText, getByText } = render(<Dropdown />);
//     const selectElement = getByLabelText('Dropdown Example');
    
//     // Simulate changing dropdown selection
//     fireEvent.change(selectElement, { target: { value: 'option1' } });

//     // Check if selected option is updated
//     const selectedOption = getByText('Selected Option: ');
//     expect(selectedOption.textContent).toContain('option1');
//   });
// });


// import React from 'react';
// import { render, fireEvent } from '@testing-library/react';
// import Dropdown from './Dropdown'; // assuming the Dropdown component is in the same directory
// import '@testing-library/jest-dom/extend-expect'; // for the additional matchers

// describe('Dropdown component', () => {
//   test('renders dropdown with initial state', () => {
//     const { getByText, getByLabelText } = render(<Dropdown />);
    
//     // Check if dropdown renders with default text
//     const selectElement = getByLabelText('Dropdown Example');
//     expect(selectElement).toBeInTheDocument();
    
//     // Check if initial option is selected
//     const selectedOption = getByText('Selected Option: ');
//     expect(selectedOption.textContent).toContain(''); // initial selected option should be an empty string
//   });

//   test('updates selected option to option1', () => {
//     const { getByLabelText, getByText } = render(<Dropdown />);
//     const selectElement = getByLabelText('Dropdown Example');
    
//     // Simulate changing dropdown selection
//     fireEvent.change(selectElement, { target: { value: 'option1' } });

//     // Check if selected option is updated
//     const selectedOption = getByText('Selected Option: option1');
//     expect(selectedOption).toBeInTheDocument();
//   });

//   test('updates selected option to option2', () => {
//     const { getByLabelText, getByText } = render(<Dropdown />);
//     const selectElement = getByLabelText('Dropdown Example');
    
//     // Simulate changing dropdown selection
//     fireEvent.change(selectElement, { target: { value: 'option2' } });

//     // Check if selected option is updated
//     const selectedOption = getByText('Selected Option: option2');
//     expect(selectedOption).toBeInTheDocument();
//   });

//   test('updates selected option to option3', () => {
//     const { getByLabelText, getByText } = render(<Dropdown />);
//     const selectElement = getByLabelText('Dropdown Example');
    
//     // Simulate changing dropdown selection
//     fireEvent.change(selectElement, { target: { value: 'option3' } });

//     // Check if selected option is updated
//     const selectedOption = getByText('Selected Option: option3');
//     expect(selectedOption).toBeInTheDocument();
//   });
// });
