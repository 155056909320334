export const AssignmentData = [
    {
        "id": 1,
        "Parameter 1": 7.8,
        "Parameter 2": 6.2,
        "Parameter 3": 5.4,
        "Parameter 4": 8.2,
        "Parameter 5": 7.3,
        "Parameter 6": 7.5,
        "Parameter 7": 9.0,
        "Parameter 8": 7.5,
    },
    {
        "id": 2,
        "Parameter 1": 7.9,
        "Parameter 2": 6.3,
        "Parameter 3": 5.2,
        "Parameter 4": 8.3,
        "Parameter 5": 7.4,
        "Parameter 6": 7.6,
        "Parameter 7": 9.1,
        "Parameter 8": 7.6,
    },
    {
        "id": 3,
        "Parameter 1": 7.7,
        "Parameter 2": 6.1,
        "Parameter 3": 5.3,
        "Parameter 4": 8.1,
        "Parameter 5": 7.2,
        "Parameter 6": 7.4,
        "Parameter 7": 8.9,
        "Parameter 8": 7.4,
    },
    {
        "id": 4,
        "Parameter 1": 7.7,
        "Parameter 2": 7.2,
        "Parameter 3": 6.4,
        "Parameter 4": 9.2,
        "Parameter 5": 8.3,
        "Parameter 6": 8.5,
        "Parameter 7": 9.5,
        "Parameter 8": 8.5,
    },
    {
        "id": 5,
        "Parameter 1": 6.8,
        "Parameter 2": 7.2,
        "Parameter 3": 8.4,
        "Parameter 4": 7.2,
        "Parameter 5": 6.3,
        "Parameter 6": 9.5,
        "Parameter 7": 8.0,
        "Parameter 8": 6.5,
    },
    {
        "id": 6,
        "Parameter 1": 7.4,
        "Parameter 2": 6.5,
        "Parameter 3": 7.7,
        "Parameter 4": 8.6,
        "Parameter 5": 7.5,
        "Parameter 6": 7.7,
        "Parameter 7": 9.3,
        "Parameter 8": 7.8,
    },
]

export const QuizData = [
    {
        "id": 1,
        "Parameter 1": 6.2,
        "Parameter 2": 8.3,
        "Parameter 3": 9.2,
        "Parameter 4": 7.5,
        "Parameter 5": 9.5,
    },
    {
        "id": 2,
        "Parameter 1": 6.3,
        "Parameter 2": 8.4,
        "Parameter 3": 9.3,
        "Parameter 4": 7.6,
        "Parameter 5": 9.6,
    },
    {
        "id": 3,
        "Parameter 1": 6.1,
        "Parameter 2": 8.2,
        "Parameter 3": 9.1,
        "Parameter 4": 7.4,
        "Parameter 5": 9.4,
    },
    {
        "id": 4,
        "Parameter 1": 7.2,
        "Parameter 2": 9.3,
        "Parameter 3": 8.2,
        "Parameter 4": 8.5,
        "Parameter 5": 7.5,
    },
    {
        "id": 5,
        "Parameter 1": 5.2,
        "Parameter 2": 7.3,
        "Parameter 3": 8.2,
        "Parameter 4": 6.5,
        "Parameter 5": 8.5,
    },
]