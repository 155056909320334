import React from "react";
import "./FooterTest.css";
// import { ImLinkedin } from "react-icons/im";
import { FaLinkedin } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";
// import { FaSquareYoutube } from "react-icons/fa6";
// import { CiYoutube } from "react-icons/ci";
// import { FaSquareXTwitter } from "react-icons/fa6";
// import { FaYoutube } from "react-icons/fa";
// import { IoLogoYoutube } from "react-icons/io";
import { FaSquareYoutube } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="footer_container_test">
        <div className="footer_contact footer_heading_test" style={{padding: '1rem'}}>
          <h1 className="footer_heading" style={{textAlign: 'left'}}>About company</h1>
          <p className="footer_text aboutPara">
            At MindMatrix, we believe that every student deserves the
            opportunity to succeed, regardless of their background. We're
            passionate about bridging the skills gap and empowering students
            from Tier 3 & 4 colleges to achieve their career aspirations.
          </p>
          <p className="footer_text aboutPara">
            Our team consists of dedicated professionals with extensive
            experience in education, industry and technology. We've already
            made a significant impact, partnering with VTU and offering our
            courses to over 200 affiliated colleges. We're continuously striving
            to expand our reach and empower more students with the skills they
            need to thrive in the modern workforce.
          </p>
        </div>

        <div className="footer_middle footer_heading_test" style={{padding: '1rem'}}>
          <h1 className="footer_heading" style={{textAlign: 'left'}}>Address</h1>
          <p className="footer_text">
            MindMatrix Labs CLi House #149, 14th Main, 4th Block Koramangala
            Bangalore - 560 034.
          </p>
          <p className="footer_text">+91 9611546444</p>
          <p className="footer_text">contact@mindmatrix.io</p>
        </div>

        <div className="follow" style={{padding: '1rem'}}>
          <h1 className="footer_heading footer_heading_test" style={{textAlign: 'left'}}>Follow us</h1>

          <div className="social_media_test">
            <Link
              to="https://www.linkedin.com/company/mindmatrixio/?originalSubdomain=in"
              target="_blank"
            >
              <FaLinkedin className="social_icon linkeDin" />
            </Link>

            <Link
              to="https://www.instagram.com/mindmatrixed/"
              target="_blank"
            >
              <FaInstagramSquare className="social_icon insta" />
            </Link>

            <Link
              to="https://www.youtube.com/@mindmatrixed2588"
              target="_blank"
            >
              <FaSquareYoutube className="social_icon youTube" />
            </Link>

            <Link
              to="https://twitter.com/mindmatrixed"
              target="_blank"
            >
             <FaSquareXTwitter className="social_icon twitter" /> 
            </Link>
            {/* <div className="image_insta"></div>
            <div className="image_facebook"></div>
            <div className="image_twitter"></div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
