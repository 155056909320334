import React from 'react'
import './UserProgress.css'
import Navbar from '../../Shared/Navbar/Navbar'

const UserProgress = () => {
  return (
    <>
      <div className='userprogress_container'>
        <Navbar />

        
      </div>
    </>
  )
}

export default UserProgress