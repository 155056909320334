const NewsData = [
    {
        title: "Chennai Metro gets WhatsApp chatbot for ticketing, other services",
        image: "https://tse1.mm.bing.net/th?id=OIP.hlg4jmFJiXLR3XUk4BJdPwHaD4&pid=Api&P=0&h=180",
        url: "https://www.gadgetsnow.com/tech-news/chennai-metro-gets-whatsapp-chatbot-for-ticketing-other-services/articleshow/100336476.cms"
    },
    {
        title: "BT plans to cut up to 55,000 jobs by 2030; job cuts break down and more",
        image: "https://static.toiimg.com/thumb/resizemode-4,msid-100335224,imgsize-120576,width-720/100335224.jpg",
        url: "https://www.gadgetsnow.com/tech-news/bt-plans-to-cut-up-to-55000-jobs-by-2030-job-cuts-break-down-and-more/articleshow/100335239.cms"
    },
    {
        title: "Machine Learning Creates New Technologies",
        image: "https://imageio.forbes.com/specials-images/imageserve/64670c15549215f4ea41e295/robot-with-education-hud/960x0.jpg?format=jpg&width=960",
        url: "https://www.forbes.com/sites/tomcoughlin/2023/05/19/machine-learning-creates-new-technologies/?ss=enterprisetech&sh=7104043c32dc"
    },
    {
        title: "Verizon And AT&T C-Band Launch Portends Future Of 5G",
        image: "https://imageio.forbes.com/specials-images/imageserve/61d66bb3a92a47213f034ea8/Verizon-5G-Home-Ultra-Wideband/960x0.jpg?format=jpg&width=960",
        url: "https://www.forbes.com/sites/bobodonnell/2022/01/06/verizon-and-att-c-band-launch-portends-future-of-5g/?sh=73c86a0bdd99"
    },
    {
        title: "Microsoft Expands Copilot AI Tool To 600 Customers In Paid Preview As ‘Digital Debt’ Weighs On Workers",
        image: "https://imageio.forbes.com/specials-images/imageserve/645a429000701b7e192796dd/In-this-photo-illustration--a-Microsoft-365-Copilot-logo-is---/1960x0.jpg?format=jpg&width=960",
        url: "https://www.forbes.com/sites/jenamcgregor/2023/05/09/microsoft-expands-copilot-ai-tool-to-600-customers-in-paid-preview-as-digital-debt-weighs-on-workers/?ss=futureofwork&sh=1cbc9deb7244"
    },
    {
        title: "Technology is the quintessential resource in education",
        image: "https://lh5.googleusercontent.com/BbpyD-3_qi5s4xLzVW9rDdgu6DRTmxVPML-eW_9HdASze3DeHcdWEhB-npBTpe8g71V19Xv2TW19wAv1c1NA9uMngKuN6zBPlAnpoN9bTFKhYY4kkvNIwL5Ho56JFasHLJ_mT0FbvCa84JV0puaC4-M",
        url: "https://www.businessoutreach.in/technology-is-the-quintessential-resource-in-education/"
    },
]

export default NewsData;