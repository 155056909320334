import React from "react";
import "./Recc_Card.css";
import { Link } from "react-router-dom";

const Recc_Card = ({ elm }) => {
  // console.log(elm.field_content_type)
  const image_url = `https://rs.npbridge.com${elm?.field_recommendation_thumbnail}`;
  // console.log(image_url);
  return (
    <>
      <Link
        to={elm?.field_recommendation_link}
        target="blank"
        style={{ textDecoration: "none" }}
      >
        <div className="rec_card_container">
          <div
            className="recc_img"
            style={elm?.field_recommendation_thumbnail ? {
              backgroundImage: `url(${image_url})`,
            } : {backgroundImage: 'url(https://images.unsplash.com/photo-1553484771-371a605b060b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80)'}}
          ></div>
          <h1 className="rec_heading">{elm.title}</h1>
        </div>
      </Link>
    </>
  );
};

export default Recc_Card;
