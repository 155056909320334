import React, { useState } from "react";
import AuthLeft from "../Shared/authLeft/authLeft";
import "./Contact.css";
import { contactMessageMail } from "../../utls/utls";

const Contact = () => {
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  let name, value;
  const handleContactChange = (e) => {
    name = e.target.name;
    value = e.target.value;

    setContactData({ ...contactData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const resp = await contactMessageMail(contactData)
      console.log(resp)
    } catch (error) {
      console.log(error)
    }
  }
  // console.log(contactData)
  return (
    <>
      <div className="contact_container">
        <AuthLeft />
        <div className="contact_right">
          <div className="contact_icon"></div>
          <div className="contact_input_container">
            <input
              type="text"
              name="name"
              value={contactData.name}
              onChange={handleContactChange}
              placeholder="Name"
              className="contact_input"
            />
          </div>

          <div className="contact_input_container">
            <input
              type="email"
              name="email"
              value={contactData.email}
              onChange={handleContactChange}
              placeholder="Email"
              className="contact_input"
            />
          </div>

          <div className="contact_input_container">
            <input
              type="number"
              name="phone"
              value={contactData.phone}
              onChange={handleContactChange}
              placeholder="Phone"
              className="contact_input"
            />
          </div>

          <div className="contact_input_container">
            <textarea
              type="text"
              name="message"
              value={contactData.message} 
              onChange={handleContactChange}
              placeholder="Message"
              className="contact_input contact_textarea"
              style={{resize: 'none'}}
            />
          </div>

          <button className="contact_submit_btn" onClick={handleSubmit}>SUBMIT</button>
        </div>
      </div>
    </>
  );
};

export default Contact;
