import React from 'react'

const MediaConnect = () => {
  return (
    <>
    <h1 style={{fontSize: '1.8rem', marginTop: '3rem'}}>This is the page for social media connect</h1>
    </>
  )
}

export default MediaConnect