import React, { useState } from "react";
import "./LandingPage.css";
import { Link } from "react-router-dom";
import NewsCard from "../Shared/NewsCard/NewsCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import NewsData from "./NewsData";
import Footer from "../Shared/Footer/Footer";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";
import Testimonial from "../Shared/TestimonialCard/Testimonial";
import TestimonialData from "./TestimonialData";
// import { getEnrollmentByUserId, getUserIdList } from "../../utls/utls";

const LandingPage = () => {
  const [opneNav, setOpenNav] = useState(false);
  const [scrollValue, setScrollValue] = useState(0);
  // const [userCourse, setUserCourse] = useState()
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    // arrows: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          rows: 2,
        },
      },
    ],
  };

  var settingsTestimonial = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          rows: 1,
        },
      },
    ],
  };

  const downloadPdf = () => {
    // usingJava Script method to get PDF file
    fetch("Brochure.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Brochure.pdf";
        alink.click();
      });
    });
  };

  window.addEventListener("scroll", () => {
    setScrollValue(window.scrollY);
  });
  // const getEnrollmentByCollege = async () => {
  //   try {
  //     const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  //     setUserCourse('test')

  //     const userList = await getUserIdList()
  //     console.log(userList)
  //     const enrolledUserId = userList?.data?.finalData?.map((elm) =>  {
  //       const userDetail = {
  //         name: elm.name,
  //         id: elm.id,
  //         college: elm.college,
  //         mobile: elm.phone,
  //         email: elm.email
  //       }
  //       return userDetail
  //     })

  //     const myAsyncFunc = async (i) => {
  //       const enrollmentList = await getEnrollmentByUserId(enrolledUserId[i].id);
  //       // console.log(enrolledUserId)
  //       const enrollmentDetails = await enrollmentList?.map((elm) => {
  //         // console.log(elm)
  //         const userObj = {
  //           name: elm.user_name,
  //           course: elm.course.name,
  //           userName: enrolledUserId[i].name,
  //           college: enrolledUserId[i].college,
  //           mobile: enrolledUserId[i].mobile,
  //           id: enrolledUserId[i].id
  //         }

  //         return userObj
  //       })
  //       console.log(enrollmentDetails)
  //       return true;
  //     };

  //     for(let i=0; i < enrolledUserId?.length; i++) {
  //       await myAsyncFunc(i);
  //       await delay(1000);
  //    }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  // getEnrollmentByCollege()
  // console.log(userCourse)

  return (
    // nav_rightRes
    <>
      <div className="landing_page_container">
        {/* Responsive navbar */}
        <div className={opneNav ? "responsiveNavbarOpen" : "responsiveNavbar"}>
          <div
            // to="https://makes.mindmatrix.io/"
            className="logo"
            style={opneNav ? { margin: "2rem auto" } : {}}
          ></div>
          <RxHamburgerMenu
            className="hamburger"
            style={opneNav ? { display: "none" } : { display: "initial" }}
            onClick={() => setOpenNav(true)}
          />
          <div className={opneNav ? "nav_rightRes" : "nav_rightResHide"}>
            <IoMdClose
              className="closeMenu"
              onClick={() => setOpenNav(false)}
            />
            <Link to="contact" className="contact_btn">
              Contact Us
            </Link>
            <Link
              to="https://learn.mindmatrix.io/users/sign_in"
              className="login_button"
            >
              Login
            </Link>
            <Link
              to="https://learn.mindmatrix.io/users/sign_up"
              className="register_button"
            >
              Sign Up
            </Link>
          </div>
        </div>

        {/* Navbar */}
        <div className="navbar">
          <div className="logo"></div>
          <div className="nav_right">
            <Link to="contact" className="contact_btn">
              Contact Us
            </Link>
            <Link
              to="https://learn.mindmatrix.io/users/sign_in"
              className="login_button"
            >
              Login
            </Link>
            <Link
              to="https://learn.mindmatrix.io/users/sign_up"
              className="register_button"
            >
              Sign Up
            </Link>
          </div>
        </div>
        <div className="stroke"></div>

        {/* Hero section */}
        <div className="main_classroom">
          <div className="classroom_img"></div>
          <p className="message">Bringing Industry to Classroom</p>
        </div>

        {/* Content Section */}
        <div className="about_info">
          <div className="left_info">
            <p>
              Incorporating industry-recognized best engineering training
              solutions, our training programs lay the foundation for preparing
              engineering graduates for future industry challenges.
            </p>

            <p>
              Industry-specific training is delivered by experienced and
              certified in-house trainers with in-depth knowledge and
              understanding of emerging technologies. Interactivity and
              outcome-based training are hallmarks of our trainers.
            </p>

            <p>
              Research, product development, services, consulting, and
              entrepreneurship are among the career options they are prepared
              for. Through our ecosystem, students can gain access to the latest
              technological advances to achieve higher levels of learning.
            </p>
          </div>

          <div className="right_info">
            <p>
              The learning needs of each learner are unique. We at Mind Matrix
              take time to understand each learner and offer sessions
              accordingly which helps them to increase their employability.
            </p>
            <div className="program_type_container">
              <div className="program_type">
                <div className="program_type_icon" id="icon_one"></div>
                <div className="program_type_text" style={{ color: "#267ACD" }}>
                  B.Tech Program
                </div>
              </div>

              <div className="program_type">
                <div className="program_type_icon" id="icon_two"></div>
                <div className="program_type_text" style={{ color: "#CFA625" }}>
                  4-year Extended Certification Parallel Program
                </div>
              </div>

              <div className="program_type">
                <div className="program_type_icon" id="icon_three"></div>
                <div className="program_type_text" style={{ color: "#E18E35" }}>
                  Short Term Certification Program
                </div>
              </div>

              <div className="program_type">
                <div className="program_type_icon" id="icon_four"></div>
                <div className="program_type_text" style={{ color: "#AC5758" }}>
                  PLEx Score - An employability indicator
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Brochure Section */}
        <div className="brochure">
          <div className="brochure_img_container">
            <div className="brochure_img"></div>
          </div>
          <div className="brochure_content">
            <h4 className="brochure_heading">To know more about Mindmatrix</h4>
            <p className="brochure_para">
              MindMatrix is a transformative platform that empowers students for
              self-reliance, fostering collaboration between industry and
              academia
            </p>
            <div className="brochure_points">
              <p className="point" style={{ color: "#E9605A" }}>
                Salient Features
              </p>
              <div className="brochure_bullet_point"></div>
              <p className="point" style={{ color: "#8088EE" }}>
                MindMatrix Programs
              </p>
              <div className="brochure_bullet_point"></div>
              <p className="point" style={{ color: "#EDA123" }}>
                Benefits
              </p>
            </div>
            <button className="brochure_button" onClick={downloadPdf}>
              Download Brochure
            </button>
          </div>
        </div>

        {/* Ceo message Section */}
        <div className="ceo_message">
          <div className="message_container">
            <h3 className="message_heading">CEO Message</h3>
            <p>Maximize the usage of local resources</p>
            <p>Accelerate impact of socio-economic growth in rural areas</p>
            <p>Knowledge creation and innovation mindset</p>
            <p>
              Encourage 'Vocal for Local', support indigenous product and
              technologies
            </p>
            <p>Support and facilitate adoption of native knowledge</p>
          </div>

          <div className="ceo_image_container">
            <div className="ceo_img"></div>
          </div>
        </div>

        {/* Vision Section */}
        <div className="vision_content">
          <div
            className="vision_img"
            style={{ transform: `rotate(${scrollValue / 6.5}deg)` }}
          ></div>
          <div className="vision_details">
            <h4 className="vision_heading">Our Vision</h4>
            <p className="target_messg">
              Stretching Minds And Re-energizing Talent
            </p>
          </div>
        </div>

        {/* Testimonial section */}
        <h4 className="testimonial_head">Testimonial</h4>
        <div className="news_container">
          <Slider {...settingsTestimonial} className="slide">
            {TestimonialData.map((elm, index) => {
              return <Testimonial data={elm} key={index} />;
            })}
          </Slider>
        </div>

        <div className="news_container">
          <Slider {...settings} className="slide">
            {NewsData.map((elm, index) => {
              return <NewsCard data={elm} key={index} />;
            })}
          </Slider>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default LandingPage;
