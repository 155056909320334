import React from "react";
import "./AdminDashboardNav.css";

const AdminDashboardNav = () => {
  return (
    <>
      <div className="adminDashboardNavContainer">
      <div className="logo"></div>
        <ul>
          <li> College List</li>
          <li> Branch List</li>
          <li> Student List</li>
          <li> Live Session</li>
          <li> Course Card</li>
          <li> News Cards</li>
          <li> Reccomended Course</li>
          <li>Upcoming Programs</li>
          <li> List of Thoughts</li>
          <li> Student Testimonial</li>
        </ul>
      </div>
    </>
  );
};

export default AdminDashboardNav;

// RiLiveLine - live
// SiCoursera - course
// TiNews - news
// FaRegNoteSticky - testimonial
// FaUniversalAccess - student
// college - LiaUniversitySolid
// TfiThought - thought
// BiGitBranch - branch
// BiLogoDiscourse - reccomended
// RiMiniProgramLine - program