import React from 'react'
import './authLeft.css'
import { Link } from "react-router-dom";

const authLeft = () => {
  return (
    <>
        <div className="login_left">
        <Link to="https://makes.mindmatrix.io/pages/Home" className="logo" id='registration_logo'></Link>
          <div className="login_hero"></div>
        </div>
    </>
  )
}

export default authLeft