import React, { useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";
// import UserImage from "./images/Hero_User.png";
import UserImage from "./images/Hero_User.png";

const Navbar = ({ userData, collegeName }) => {
  const [openNavDash, setOpenNavDash] = useState(false);
  // console.log(UserImage)
  return (
    <>
      <div
        className={
          openNavDash ? "navbar_dashboard_res_Open" : "navbar_dashboard"
        }
      >
        <div className="logo"></div>
        {/* <h1 style={{marginRight: "20rem"}}>{collegeName}</h1> */}
        {openNavDash ? (
          <IoMdClose
            className="close_Menu_dashboard"
            onClick={() => setOpenNavDash(false)}
          />
        ) : (
          <RxHamburgerMenu
            className="hamburger_dashboard"
            onClick={() => setOpenNavDash(true)}
          />
        )}
        <nav>
          {/* <ul className="nav_links">
              <li>
                <p>Contact us</p>
              </li>
              <li>
                <p>Activities</p>
              </li>
            </ul> */}
        </nav>

        <Link
          to="https://learn.mindmatrix.io/account"
          style={{ textDecoration: "none" }}
          className="user_link"
        >
          <div className="user_info">
            <div
              className="user_img"
              style={
                userData?.user_image
                  ? { backgroundImage: `url(${userData?.user_image})` }
                  : { backgroundImage: `url(${UserImage})` }
              }
            ></div>
            <h1 className="user_name">{userData?.name}</h1>
          </div>
        </Link>
      </div>
    </>
  );
};

export default Navbar;
