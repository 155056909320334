import React from "react";
import './Testimonial.css'

const Testimonial = ({data}) => {
    // console.log(data.image)
  return (
    <>
      <div className="testimonial_container">
        <div className="testimonial">
          <div className="testimonial_img_container">
            <div className="testimonial_img" style={{backgroundImage: `url(${data?.image})`}}></div>
          </div>
          {/* backgroundImage: `${data.image}` */}
          <div className="testimonial_details">
            <h1 className="testimonial_name">{data.name}</h1>
            <h1 className="testimonial_designation">Student</h1>
            <p className="testimonial_para">
              {data?.studentWord}
               <br />
              <br />
              !!! Thank you MindMatrix!!!
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
