import React from "react";
import "./CourseCard.css";
import { Link } from "react-router-dom";

const CourseCard = ({ data }) => {
  const course_page_url = `https://learn.mindmatrix.io/courses/${data?.course?.slug}`;
  const percentage = Math.floor(data?.percentage_completed);
  // console.log("Card Data", data)
  
  return (
    <>
      <Link
        to={course_page_url}
        className="course_card"
        style={{ textDecoration: "none" }}
      >
        <div
          className="course_card_img"
          style={{
            backgroundImage: `url(${data?.product?.card_image_url})`,
          }}
        ></div>
        <h1 className="course_name">{data?.course?.name}</h1>
        <p className="course_category">{data?.collection?.name}</p>
        <div className="course_card_footer">
          {// eslint-disable-next-line eqeqeq
          <h1 className="course_price">{(data?.product?.price == 0) ? 'Free': data?.product?.price}</h1>}
          {(data?.percentage_completed) ? <h1 className='percentage_completed'>{percentage}% completed</h1> : null}
        </div>
      </Link>
    </>
  );
};

export default CourseCard;
