import React, { useEffect, useState } from "react";
import "./ActivityPost.css";
import { createPensilPost, pensilGroupById, ActivityPostChatGptResponse } from "../../utls/utls";
import { AiOutlineClose } from "react-icons/ai";
import { TfiGallery } from "react-icons/tfi";
// import { useNavigate } from "react-router-dom";

const ActivityPost = () => {
  const [postData, setPostData] = useState({
    title: "",
    description: "",
    groupId: "63622036cf8a096c26ef2522",
    tabId: "63622036cf8a096c26ef2526",
  });
  const [tabData, setTabData] = useState();
  // 6362208acf8a096c26ef30ab
  // const navigate = useNavigate();
  const tabId = window.location.pathname.split("/")[2];
  console.log(tabData);
  const token = localStorage.getItem("pensilToken");
  let name, value;
  const handlePostChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setPostData({ ...postData, [name]: value, tabId });
  };

  const handlePostSubmit = async () => {
    const resp = await createPensilPost(postData, token);
    const aiResp = await ActivityPostChatGptResponse(postData.description)
    console.log(resp);
    console.log("AI", aiResp)
  };

  // const handleFileChange = async () => {
  //   try {

  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  // const responseAi = async () => {
  //   const resp = await ActivityPostChatGptResponse("Greetings ! Venture into the world of construction and unveil eco-friendly practices! 🏗️ Discover green building materials and ustainable methods. Capture the essence through your lens and share your eco-revelations in the 'Explore N Exhibit' subgroup under 'Submissions' Let's build a sustainable future together! 🌱🔨 greenConstruction Submissions accepted only till 2nd Dec 2023")
  //   console.log(resp)
  // }
  useEffect(() => {
    async function fetchGroupName(tabId, token) {
      const getGroupName = await pensilGroupById(token);

      const tabIdArr = []
      const obj = {value: tabId}
      tabIdArr.push(obj)
      const result = getGroupName?.data?.groups[1]?.tabs?.filter((groupId) => tabIdArr?.some((tabId) => groupId?.id === tabId?.value))

      setTabData(result[0])
    }
    fetchGroupName(tabId, token)
    // responseAi()
  }, [tabId, token]);
  // console.log(postData)
  return (
    <>
      <div className="activity_post_container">
        <div className="activity_overlay">
          <div className="activity_post_banner">
            <div className="activity_header">
              <div style={{ display: "flex" }}>
                <h1 className="activity_header_text">Create new post in</h1>
                <h1
                  className="activity_header_text"
                  style={{ color: "#929292" }}
                >
                  {tabData?.name}
                </h1>
              </div>
              <AiOutlineClose
                className="activity_icon"
                size={25}
                onClick={() => window.close()}
              />
            </div>

            <input
              type="text"
              className="input_title"
              name="title"
              value={postData.title}
              onChange={handlePostChange}
              placeholder="Heading (optional)"
            />
            <input
              type="text"
              className="input_description"
              name="description"
              value={postData.description}
              onChange={handlePostChange}
              placeholder="What's on your mind, Tirumal ?"
            />

            <div className="activity_footer">
              <TfiGallery className="activity_icon" size={25} />
              {/* <input type="file" onChange={handleFileChange}/> */}
              <button onClick={handlePostSubmit} className="postButton">
                Post
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityPost;