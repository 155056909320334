import React, { useState, useEffect } from "react";
import "./UserAssistance.css";
import { getUserDetail, getRecommendation } from "../../utls/utls";
import Navbar from "../Shared/Navbar/Navbar";
import Support from "./AssistanceComponent/Support/Support";
import Reccomendation from "./AssistanceComponent/Reccomendation/Reccomendation";
import MediaConnect from "./AssistanceComponent/MediaConnect/MediaConnect";
import FeedBack from "./AssistanceComponent/FeedBack/FeedBack";

const UserAssistance = () => {
  const [userData, setUserData] = useState({
    first_name: "",
    name: "",
    user_image: "",
  });
  const [recommendData, setRecommendedData] = useState();
  const [showComponent, setShowComponent] = useState("support");
  const [isActive, setIsActive] = useState("support")

  const getRecommendationByEngine = async () => {
    const result = await getRecommendation();
    setRecommendedData(result?.data);
  };
  const user_id = localStorage.getItem("id");
  // console.log(showComponent);

  const manageButton = (e) => {
    const value = e.target.id;
    setIsActive(value);
    setShowComponent(value)
  }

  useEffect(() => {
    const userDetails = async (user_id) => {
      try {
        const res = await getUserDetail(user_id);
        setUserData({
          ...userData,
          first_name: res?.data?.first_name,
          name: res?.data?.full_name,
          user_image: res?.data?.avatar_url,
        });
      } catch (error) {
        console.log(error);
      }
    };

    if (!userData.name) {
      userDetails(user_id);
    }
    if (!recommendData) {
      getRecommendationByEngine();
    }
  }, [user_id, recommendData, userData]);
  return (
    <>
      <div className="userAssistanceContainer">
        <Navbar userData={userData} />
        {/* <h1 style={{ margin: "2rem 0" }}>
          Reccomendation by AI engine for {userData?.first_name}
        </h1> */}

        <div className="select_buttons">
          {/* <button className={isActive === "rec"? "select_button active" : "select_button notActive"} id="rec" onClick={(e)=> {
            manageButton(e)
          }}>A.I. Rec</button> */}
          <button className={isActive === "support"? "select_button active" : "select_button notActive"} id="support" onClick={(e)=> {
            manageButton(e)
          }}>Support</button>

          {/* <button className={isActive === "connect"? "select_button active" : "select_button notActive"} id="connect" onClick={(e)=> {
            manageButton(e)
          }}>Media Connect</button>
          <button className={isActive === "feedback"? "select_button active" : "select_button notActive"} id="feedback" onClick={(e)=> {
            manageButton(e)
          }}>Feedback</button> */}
        </div>

        <div style={showComponent === "support" ? { display: "initial" } : { display: "none" }}>
          <Support />
        </div>
        <div style={showComponent === "rec" ? { display: "initial" } : { display: "none" }}>
          <Reccomendation data= {recommendData}/>
        </div>
        <div style={showComponent === "connect" ? { display: "initial" } : { display: "none" }}>
          <MediaConnect />
        </div>
        <div style={showComponent === "feedback" ? { display: "initial" } : { display: "none" }}>
          <FeedBack />
        </div>
      </div>
    </>
  );
};

export default UserAssistance;
