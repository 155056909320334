import React, { useState } from 'react'
import "./StudentReport.css"
import { Circle } from "rc-progress";
// import { AssignmentData, QuizData } from "./ReportData"
import ReportModal from '../ReportModal/ReportModal';


const StudentReport = ({ course, setShowModal, showModal }) => {
  const [selectedCourse, setSelectedCourse] = useState()

  return (
    <>
      <div style={{position: "relative"}}>{showModal ? <ReportModal setShowModal={setShowModal} /> : null}</div>
      <div className='studentReport_container'>
        <div className='studentReport'>
          <h1 style={{ marginBottom: '2rem', color: '#008080', fontFamily: "montserrat" }}>Report</h1>
          {course?.length > 0 && <h1 style={{ color: '#008080', fontSize: '1.8rem', textAlign: "left", fontFamily: "montserrat" }}>Courses enrolled : {course?.length}</h1>}
          <h1 style={{ color: '#008080', fontSize: '1.8rem', margin: '0.5rem 0', textAlign: "left", fontFamily: "montserrat" }}>Assignments submitted : 0</h1>

          <div style={{ backgroundColor: '#008080', padding: '0.8rem 2.5rem', borderRadius: "100px", margin: "1.5rem 0" }}>
            <select
              name='courseEnrolled'
              id='courseEnrolled'
              onChange={(e) => setSelectedCourse(e.target.value)}
              value={selectedCourse}
              style={{ color: 'white', fontSize: '1.8rem', backgroundColor: '#008080', cursor: "pointer" }}
            >
              <option value="courseEnrolled">
                Choose Course
              </option>
              {course?.map((elm, index) => (
                <option value={elm?.course?.name} key={index}>
                  {elm?.course?.name}
                </option>
              ))}
            </select>
          </div>

          {selectedCourse && (
            <div>
              <h1 style={{ textAlign: 'center', fontSize: '2rem', fontWeight: 'lighter', margin: '1rem 0' }}>Details of the course : {selectedCourse}</h1>
              <DetailsCard detailType="Assignments" percentage={70} headingText="7 Assignment submitted out of 14" setShowModal={setShowModal} />
              {/* Dropdown for assignment list */}
              <DetailsCard detailType="Live Sessions" percentage={33.33} headingText="12 Live session attended out of 20" />
              <DetailsCard detailType="Quizzes" percentage={60} headingText="5 Quiz attempted out 15" />
            </div>
          )}
          {/* <div>
            <select
              onChange={(e) => setSelectedDetail(e.target.value)}
              value={selectedDetail}
            >
              <option>Choose Details</option>
              {reportOption?.map((elm, index) => (
                <option className='option' key={index}>
                  {elm}
                </option>
              ))}
            </select>
          </div> */}
          {/* {renderDetail()} */}
        </div>
      </div>
    </>
  )
}

export default StudentReport

const DetailsCard = ({ detailType, percentage, headingText, setShowModal }) => {
  return (
    <>
      <div className='deatailsCard'>
        <h1 style={{ fontSize: '1.8rem', marginBottom: '1rem', color: '#008080', fontFamily: "montserrat" }}>{detailType}</h1>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontFamily: "montserrat" }}>
          <div>
            <h1 style={{ fontSize: '1.3rem', marginBottom: '1rem', textAlign: 'left', fontFamily: "montserrat", fontWeight: "400" }}>{headingText}</h1>
            {detailType === "Assignments" ? <DropdownComp setShowModal={setShowModal} /> : null}
            {detailType === "Quizzes" ? <QuizPercentage percent={72} /> : null}
          </div>
          <Circle
            className='circlePercentage'
            percent={percentage}
            strokeWidth={7}
            steps={{
              count: 10,
              space: 1,
            }}
            strokeColor={"#0f8693"}
          />
        </div>
      </div>
    </>
  )
}

const QuizPercentage = ({ percent }) => {
  return (
    <>Average Score - {percent}%</>
  )
}

const DropdownComp = ({ setShowModal }) => {
  return (
    <>
      <select onChange={() => setShowModal(true)}>
        <option>Assignment 1</option>
        <option>Assignment 2</option>
        <option>Assignment 3</option>
        <option>Assignment 4</option>
        <option>Assignment 5</option>
        <option>Assignment 6</option>
        <option>Assignment 7</option>
      </select>
    </>
  )
}
