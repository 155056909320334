import React from "react";
import "./VerifyModal.css";
import { verifyUserDb } from "../../../utls/utls";

const VerifyModal = ({
  setVerifyUser,
  verifyUser,
  showModal,
  setShowModal,
  sendCodeHandler
}) => {
  // const [resendTimer, setResendTimer] = useState(59)

  const handleVerify = async (e, verifyUser) => {
    e.preventDefault();
    const res = await verifyUserDb(verifyUser);
    console.log(res)
    setShowModal(false);

  };
  return (
    <>
      <div
        className="verify_modal_container"
        style={showModal ? { display: "flex" } : { display: "none" }}
      >
        <div className="close_btn" onClick={() => setShowModal(false)}></div>
        <h1 className="modal_heading">Verify your email address</h1>
        <p className="modal_para">
          Please check your inbox for verification code sent to
        </p>
        <h3 className="modal_mail">{verifyUser.email}</h3>
        <div className="inputBOx">
          <input
            type="number"
            name="secretCode"
            placeholder="Enter Code"
            id="code"
            value={verifyUser.secretCode}
            onChange={(e) =>
              setVerifyUser({ ...verifyUser, secretCode: e.target.value })
            }
            className="email_text"
          />
        </div>
        <h3 className="modal_resend_code">
          Not received code?<button onClick={sendCodeHandler} style={{border: "none", backgroundColor: "transparent"}}> Resend Code</button>
        </h3>
        <button
          className="modal_btn"
          onClick={(e) => handleVerify(e, verifyUser)}
        >
          Verify
        </button>
      </div>
    </>
  );
};

export default VerifyModal;


// import React, { useCallback, useEffect, useState } from 'react'
// import { Button } from "@/components/ui/button"

// const App = () => {
//   const [timer, setTimer] = useState(60)
//   const timeOutCallBack = useCallback(() => setTimer(currTimer => currTimer - 1))

//   useEffect(() => {
//     timer > 0 && setTimeout(timeOutCallBack, 1000)
//   }, [timer, timeOutCallBack])

//   console.log(timer)

//   const resetTimer = function(){
//     if(timer === 0){
//       setTimer(60)
//     }
//   }
//   return (
//     <div>
//        <Button onClick={resetTimer}>Resend OTP ({timer})</Button>
//     </div>
//   )
// }

// export default App
