import React from 'react'
import './MenuBar.css'
import { Link } from 'react-router-dom'
import UserImage from "./image/Hero_User.png";

const MenuBar = ({ userData }) => {
    return (
        <>
            <div className='menuBar_container'>
                <div className='menu_logo'></div>
                <div className='menuBar_right'>
                    <Link to="/"
                        style={{ textDecoration: "none" }} className='menuBar_link'>Home</Link>
                    {/* <Link to="/report"
                        style={{ textDecoration: "none" }} className='menuBar_link'>Report</Link> */}
                    <Link to="https://learn.mindmatrix.io/collections"
                        style={{ textDecoration: "none" }} className='menuBar_link'>Courses</Link>
                    {/* <h2 className='menuBar_link'>Recommendations</h2>
                    <h2 className='menuBar_link'>Report</h2>
                    <h2 className='menuBar_link'>Support</h2> */}
                    <Link
                        to="https://learn.mindmatrix.io/account"
                        style={{ textDecoration: "none" }}
                        className="user_link"
                    >
                        <div className="user_info">
                            {/* <h4 className="user_name menuBar_link">{userData?.name}</h4> */}
                            <Link to="https://learn.mindmatrix.io/account"
                                style={{ textDecoration: "none" }} className='user_name menuBar_link'>{userData?.name}</Link>
                            <div
                                className="user_img"
                                style={
                                    userData?.user_image
                                        ? { backgroundImage: `url(${userData?.user_image})` }
                                        : { backgroundImage: `url(${UserImage})` }
                                }
                            ></div>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default MenuBar